<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <tr>
    <td><input type="checkbox" id="selected_row" name="row" v-model="checked" @change="this.moveToSelectedList()"></td>
    <td>
      <a
        :href="
          '#' +
          this.$route.path +
          (this.defaultGraph
            ? '/graph/' + encodeURIComponent(this.graphURI)
            : '') +
          '/node/' +
          encodeURIComponent(this.fragment)
        "
        >{{ fragment }}</a
      >
    </td>
    <td>{{ mainType }}</td>
    <td>{{ label }}</td>
    <td  style="text-align: center;">{{ asSubjectNum }}</td>
    <td  style="text-align: center;">{{ asPredicateNum }}</td>
    <td  style="text-align: center;">{{ asObjectNum }}</td>
  </tr>
</template>

<script>
export default {
  data() {
    return {
      checked: false
    };
  },
  methods:{
    moveToSelectedList(){
      let obj = {}
      obj["fragment"] = this.fragment;
      if(this.checked === true) obj['addOrDelete'] = 1;
      else obj['addOrDelete'] = 0;
      this.$emit("getRow", obj);
    }
  },
  props: {
    datasetID: { required: false, type: String },
    defaultGraph: { required: false, type: Boolean },
    graphURI: { required: false, type: String },
    fragment: { required: true, type: String },
    mainType: { required: false, type: String },
    label: { required: false, type: String },
    asSubjectNum: { required: true},
    asPredicateNum: { required: true},
    asObjectNum: { required: true},
  },
};
</script>

<style>
</style>