<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <a id="overview" />
  <table class="details">
    <tbody>
      <tr>
        <td class="name">Dataset:</td>
        <td>
          <router-link
            v-if="graphURI !== undefined"
            class="truncate"
            :to="{ name: 'SSTDataset', params: { dsID: dsID } }"
            >{{ dsID }}</router-link
          >
          <span v-else class="truncate">{{ dsID }}</span>
        </td>
      </tr>
      <tr v-if="commitID !== undefined">
        <td class="name">Commit:</td>
        <td>
          <span class="commit-id">{{ commitID }}</span>
        </td>
      </tr>
      <tr>
        <td class="name">Named Graph:</td>
        <td>
          <span class="truncate" v-if="graphURI !== undefined"
            ><span v-if="isExternal">{{ graphURI }}</span>
            <router-link
              v-else
              :to="{
                name: 'SSTDataset',
                params: { dsID: actualGraphID },
              }"
              >{{ graphURI }}</router-link
            >
          </span>
          <span
            class="truncate"
            v-else-if="!isURIDefaultGraph(actualGraphURI)"
            >{{ actualGraphURI }}</span
          >
          <span class="truncate" v-else> Default Graph </span>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-if="dsServiceSessions.has(dsID)">
    <input
      class="commit-message-style"
      v-model="commitMessage"
      placeholder="Enter commit message"
    />
    <input
      type="submit"
      value="Commit"
      :disabled="!commitMessage"
      @click="commitEditSession"
    />
    <input type="submit" value="Discard" @click="discardEditSession" />
  </div>
  <graph-imports
    v-if="imports || importedBy"
    :dsID="dsID"
    :graphURI="actualGraphURI"
    :imports="imports"
    :importedBy="importedBy"
  />
  <graph-derived-data v-if="derived" :dsID="dsID" :derived="derived" />
  <a id="nodes" />
  <h2>Nodes 
    <!-- | <span @click="this.export()" class="link">Export</span> -->
    | <span @click="this.deleteRow()" class="link">Delete Row</span>
    | <span @click="this.export()" class="link">Download As</span>
      <select v-model="selectedValue" style="margin-left: 10px;width: 100px;height: 20px;">
        <option value="sst">sst</option>
        <option value="ttl">ttl</option>
      </select>
  </h2>
  <ib-node-list
    v-if="nodes !== null"
    :defaultGraph="graphURI === undefined"
    :nodes="nodes"
    @getSelectedDataList="this.getSelectedDataList"
  />
  <a id="commits" />
  <div v-if="commits !== undefined">
    <commit-list
      :commits="commits"
      :dsID="dsID"
      :graphURI="graphURI === undefined ? undefined : graphURI"
    />
  </div>
  <div v-if="derived !== undefined && displayDerivedJSON">
    <h2 class="subheader">Derived JSON</h2>
    <vue-json-pretty class="json" :data="derived"> </vue-json-pretty>
  </div>
</template>

<script>
import GraphImports from "./GraphImports.vue";
import GraphDerivedData from "./GraphDerivedData.vue";
import IBNodeList from "./IBNodeList.vue";
import CommitList from "./CommitList.vue";
import { useGlobalInfo } from "../stores/globalInfo";
import { useDatasetEditSessions } from "../stores/dsEditSessions";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import startsWith from "lodash.startswith";
import sortBy from "lodash.sortby";
// import { saveAs } from 'file-saver';
export default {
  name: "GraphInfo",
  components: {
    GraphImports,
    GraphDerivedData,
    ibNodeList: IBNodeList,
    VueJsonPretty,
    CommitList,
  },
  props: {
    commitID: { required: false, type: String },
    dsID: { required: true, type: String },
    graphURI: { required: false, type: String },
  },
  setup() {
    const globalInfo = useGlobalInfo();
    const dsEditSessions = useDatasetEditSessions();
    return {
      globalInfo,
      dsServiceSessions: dsEditSessions.serviceSessions,
    };
  },
  data() {
    return {
      actualGraphID: undefined,
      actualGraphURI: undefined,
      imports: undefined,
      importedBy: undefined,
      isExternal: false,
      nodes: null,
      derived: undefined,
      commits: undefined,
      displayDerivedJSON: localStorage.displayDerivedJSON,
      commitMessage: "",
      selectedList:[],
      selectedValue:"sst"
    };
  },
  created() {
    this.$watch(
      () => [this.commitID, this.dsID, this.graphURI],
      () => this.fetchData(),
      { immediate: true }
    );
  },
  methods: {
    export(){
      if(this.selectedValue=="sst"){
        this.exportSST()
      }else{
        this.exportTTL()
      }
    },
    exportSST(){
      let type = "sst"
      this.axios({
        method: "get",
        url: "/export?dsID="+this.dsID+"&type=" + type
      }
      ).then(async (response) => {
        console.log(response)
        const opts = {
          suggestedName: `${this.dsID}.sst`,
          types: [{
            description: "Binary File",
            accept: {'text/plain': ['.sst']},
          }],
        };
        const handle = await window.showSaveFilePicker(opts);
        const writable = await handle.createWritable();
        await writable.write(response.data);
        await writable.close();
      });
    },
    exportTTL(){
      let type = "ttl"
      this.axios({
        method: "get",
        url: "/export?currentId="+this.dsID+"&type=" + type
      }
      ).then(async (response) => {
        console.log(response)
        const opts = {
          suggestedName: `${this.dsID}.ttl`,
          types: [{
            description: "Turtle File",
            accept: {'text/plain': ['.ttl']},
          }],
        };
        const handle = await window.showSaveFilePicker(opts);
        const writable = await handle.createWritable();
        await writable.write(response.data);
        await writable.close();
      });
    },
    getSelectedDataList(data){
      if(data["addOrDelete"] == 1){
        this.selectedList.push(data["fragment"])
      }else{
        this.selectedList = this.selectedList.filter(item => item != data["fragment"])
      }
    },
    deleteRow(){
      var formData = new FormData()
      formData.append('selectedList',this.selectedList)
      formData.append('dsID',this.dsID)
      this.axios({
        method: "post",
        url: "/deleteNodes",
        data:formData
      }
      ).then(async (response) => {
        if(response.status == 200) window.location.reload();
      });
    },
    fetchData() {
      const sessionID = this.dsServiceSessions.get(this.dsID);
      this.axios
        .get(
          "/dataset/" +
            this.dsID +
            (sessionID !== undefined && this.commitID === undefined
              ? "/session/" + sessionID
              : this.commitID !== undefined
              ? "/commit/" + this.commitID
              : "") +
            (this.graphURI !== undefined
              ? "/graph/" +
                encodeURIComponent(encodeURIComponent(this.graphURI)) // Double escaping
              : "")
        )
        .then((response) => {
          this.actualGraphID = response.data.graphID;
          this.actualGraphURI = response.data.graphURI;
          this.nodes = sortBy(response.data.nodes, [
            (o) =>
              !startsWith(o.mainType, "(") ? o.mainType : "\xff" + o.mainType,
            (o) => o.fragment,
          ]);
          this.imports = undefined;
          if (response.data.imports !== undefined) {
            this.imports = sortBy(response.data.imports);
          }
          this.importedBy = undefined;
          if (response.data.importedBy !== undefined) {
            this.importedBy = sortBy(response.data.importedBy);
          }
          if (response.data.isExternal !== undefined) {
            this.isExternal = response.data.isExternal;
          }
          if (response.data.derived !== undefined) {
            this.derived = response.data.derived;
          }
          this.commits = undefined;
          if (response.data.commits !== undefined) {
            this.commits = response.data.commits;
          }
          this.globalInfo.updateLoggedInUser(response.data);
        });
    },
    isURIDefaultGraph(graphURI) {
      return startsWith(graphURI, "urn:uuid:");
    },
    discardEditSession() {
      this.dsServiceSessions.delete(this.dsID);
      this.fetchData();
    },
    commitEditSession() {
      const sessionID = this.dsServiceSessions.get(this.dsID);
      const dsID = this.dsID;
      const that = this;
      this.axios({
        method: "post",
        url: "/dataset/" + dsID + "/commit",
        data: {
          sessionID: sessionID,
          message: this.commitMessage,
        },
        maxRedirects: 0,
        validateStatus: function (status) {
          return status == 201;
        },
      }).then(() => {
        this.dsServiceSessions.delete(dsID);
        that.fetchData();
      });
    },
  },
};
</script>

<style scoped>
.json {
  margin: 0 0 0 18vw;
}
input {
  margin-left: 5px;
  margin-right: 5px;
}
.commit-message-style {
  width: 23em;
}
</style>
