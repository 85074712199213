<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <table class="list">
    <thead>
      <th></th>
      <th>Fragment</th>
      <th>Main Type</th>
      <th>Id/Label</th>
      <th>as Subject</th>
      <th>as Predicate</th>
      <th>as Object</th>
    </thead>
    <tbody v-for="item in nodes" :key="item.fragment">
      <ib-node-item
        :datasetID="item.datasetID"
        :defaultGraph="defaultGraph"
        :graphURI="item.graphURI"
        :fragment="item.fragment"
        :mainType="item.mainType"
        :label="item.label"
        :asSubjectNum = "item.asSubjectNum == 0? '-' : item.asSubjectNum"
        :asPredicateNum = "item.asPredicateNum == 0? '-' : item.asPredicateNum"
        :asObjectNum = "item.asObjectNum == 0? '-' : item.asObjectNum"
        @getRow="this.getRow"
      />
    </tbody>
  </table>
</template>

<script>
import IBNodeItem from "./IBNodeItem.vue";
export default {
  components: {
    ibNodeItem: IBNodeItem,
  },
  props: {
    defaultGraph: { required: false, type: Boolean },
    nodes: { required: true, type: Array },
  },
  methods:{
    getRow(data){
      this.$emit("getSelectedDataList", data);
    }
  },
};
</script>

<style>
</style>