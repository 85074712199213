<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <div class="container">
    <div class="table-row">
      <div class="table-cell">
        <h2 class="subheader">Direct Imports</h2>
        <span v-for="(imprt, i) in imports" :key="i">
          <router-link
            :to="{
              name: 'SSTDatasetGraph',
              params: { dsID: dsID, graphURI: imprt.uri },
            }"
            >{{ importLabel(imprt) }}</router-link
          ><span v-if="i + 1 < imports.length">,</span><br />
        </span>
        <span v-if="!imports">&mdash;<br /></span>
      </div>
      <div class="table-cell">
        <h2 class="subheader">Directly Imported by</h2>
        <span v-for="(imprt, i) in importedBy" :key="i">
          <router-link
            :to="{
              name: 'SSTDatasetGraph',
              params: {
                dsID: imprt.id,
                graphURI: graphURI,
              },
            }"
            >{{ importLabel(imprt) }}</router-link
          ><span v-if="i + 1 < importedBy.length">,</span><br />
        </span>
        <span v-if="!importedBy">&mdash;<br /></span>
      </div>
    </div>
  </div>
</template>

<script>
import startsWith from "lodash.startswith";
export default {
  name: "GraphImports",
  props: {
    dsID: { required: true, type: String },
    graphURI: { required: true, type: String },
    imports: { required: false, type: Object },
    importedBy: { required: false, type: Object },
  },
  methods: {
    importLabel(imprt) {
      return startsWith(imprt.uri, "urn:uuid:") ? imprt.id : imprt.uri;
    },
  },
};
</script>

<style scoped>
.container {
  display: table;
  margin: 0 auto;
}

.table-row {
  display: table-row;
}
.table-cell {
  display: table-cell;
  padding: 2px;
  width: 50%;
}
</style>>
