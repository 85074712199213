<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <div v-if="haveDerivedData()">
    <h2 class="subheader">Derived Data</h2>
    <table class="details">
      <tbody>
        <tr v-for="(v, k) in derivedData" :key="k">
          <td class="name">{{ k }}</td>
          <td v-if="Array.prototype.isPrototypeOf(v)">
            <ul>
              <li v-for="(e, i) in v" :key="i">{{ e }}</li>
            </ul>
          </td>
          <td v-else-if="/(^|\.).+\.uri$/.test(k)">
            <router-link
              :to="{
                name: 'IBNode',
                params: {
                  dsID: dsID,
                  graphURI: derived[k + '.graph'],
                  fragment: derived[k + '.fragment'],
                },
              }"
              >{{ v }}</router-link
            >
          </td>
          <td v-else>{{ v }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "GraphDerivedData",
  props: {
    dsID: { required: true, type: String },
    derived: { required: true, type: Object },
  },
  computed: {
    derivedData() {
      const d = {};
      if (this.derived !== undefined) {
        const input = (m) => (m == null ? undefined : m.input);
        for (const [k, v] of Object.entries(this.derived)) {
          switch (k) {
            case "graphID":
            case "graphURI":
            case "directImport":
            case input(k.match(/\.uri\.graph$/)):
            case input(k.match(/\.uri\.fragment$/)):
              break;
            default:
              d[k] = v;
          }
        }
      }
      return d;
    },
  },
  methods: {
    haveDerivedData() {
      return this.derivedData && Object.entries(this.derivedData).length > 0;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: disc;
  padding: 0 0 0 8px;
  margin: 0;
}
li {
  padding: 0;
  margin: 0 0 0 10px;
}
</style>
