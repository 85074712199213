<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

 <template>
  <graph-info
    v-if="dsID"
    :commitID="commitID"
    :dsID="dsID"
    :graphURI="graphURI"
  ></graph-info>
</template>

 <script>
import GraphInfo from "../components/GraphInfo.vue";
import { useRecentStore } from "../stores/recent";

export default {
  name: "SSTDataset",
  components: {
    GraphInfo,
  },
  setup() {
    const recentStore = useRecentStore();
    return { recentStore };
  },
  props: {
    commitID: { required: false, type: String },
    dsID: { required: false, type: String },
    graphURI: { required: false, type: String },
  },
  beforeMount() {
    this.recentRedir();
  },
  beforeUpdate() {
    this.recentRedir();
  },
  methods: {
    recentRedir() {
      const recentDatasetID = this.recentStore.recentDatasetID;
      const recentGraphURI = this.recentStore.recentGraphURI;
      if (this.$route.params.dsID === "") {
        this.$router.replace({ name: "SSTRepository" });
      } else if (
        this.$route.params.dsID !== recentDatasetID ||
        this.$route.params.graphURI !== recentGraphURI
      ) {
        this.recentStore.recentDatasetID = this.$route.params.dsID;
        this.recentStore.recentGraphURI = this.$route.params.graphURI;
      }
    },
  },
};
</script>

 <style>
</style>