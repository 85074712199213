<!--
 Copyright 2022 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <h2 class="subheader">Commits</h2>
  <table class="list">
    <tbody>
      <tr v-for="(c, i) in commits" :key="i">
        <td>
          <span class="commit-id">{{ c.commitID }}</span
          >&nbsp;<!-- <a href="#">&#x0394;</a>&nbsp; --><router-link
            @click="scrollToTop()"
            :to="{
              name:
                this.graphURI == undefined
                  ? 'SSTDatasetCommit'
                  : 'SSTDatasetCommitGraph',
              params:
                this.graphURI == undefined
                  ? { commitID: c.commitID, dsID: this.dsID }
                  : {
                      commitID: c.commitID,
                      dsID: this.dsID,
                      graphURI: this.graphURI,
                    },
            }"
            >&#xFF1E;</router-link
          >
          {{ c.author }} <span class="bold">&#183;</span> {{ c.authorDate }}
          <br /><span v-if="c.messageOrReason.message">{{
            c.messageOrReason.message
          }}</span
          ><span v-if="c.messageOrReason.reason"
            ><em>Reason: {{ c.messageOrReason.reason }}</em></span
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "CommitList",
  props: {
    commits: { required: true, type: Object },
    dsID: { required: true, type: String },
    graphURI: { required: false, type: String },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
</style>